import * as React from "react"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Seo from "../components/seo2023"
import Layout from "../components/layout2023"
import ClientsV2 from "../componentsv2/clientsv2/clientsv2"

const Clients = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="Clients" />
    <ClientsV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default Clients
